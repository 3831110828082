import { render, staticRenderFns } from "./forsubmit.vue?vue&type=template&id=5ed9f5b2&scoped=true&"
import script from "./forsubmit.vue?vue&type=script&lang=js&"
export * from "./forsubmit.vue?vue&type=script&lang=js&"
import style0 from "./forsubmit.vue?vue&type=style&index=0&id=5ed9f5b2&scoped=true&lang=less&"
import style1 from "./forsubmit.vue?vue&type=style&index=1&lang=less&"
import style2 from "./forsubmit.vue?vue&type=style&index=2&id=5ed9f5b2&lang=less&scoped=scoped&"
import style3 from "./forsubmit.vue?vue&type=style&index=3&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ed9f5b2",
  null
  
)

export default component.exports