import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import {post,get,patch,put} from "@/http/http.js"
import commonFun from "./assets/js/commonFun.js"
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import less from 'less'
Vue.config.productionTip = false

Vue.prototype.$post=post;
Vue.prototype.$get=get;
Vue.prototype.$patch=patch;
Vue.prototype.$put=put;
Vue.prototype.$commonFun=commonFun;
Vue.use(ElementUI);
Vue.use(less);

import VueAMap from 'vue-amap'
setTimeout(()=>{
	localStorage.clear()
	Vue.use(VueAMap)
},0)
// 初始化vue-amap
VueAMap.initAMapApiLoader({
    key: 'b39f913db1f0d1797ac8fa8dfb09ae79',
    plugin: [
        'AMap.Geocoder', // 坐标反查
        'AMap.Autocomplete', // 输入提示插件
        'AMap.PlaceSearch', // POI搜索插件
        'AMap.Scale', // 右下角缩略图插件 比例尺
        'AMap.OverView', // 地图鹰眼插件
        'AMap.ToolBar', // 地图工具条
        'AMap.MapType', // 类别切换控件，实现默认图层与卫星图、实施交通图层之间切换的控制
        'AMap.PolyEditor', // 编辑 折线多，边形
        'AMap.CircleEditor', // 圆形编辑器插件
        'AMap.Geolocation', // 定位控件，用来获取和展示用户主机所在的经纬度位置
        'AMap.TruckDriving', // 路径规划
    ],
    v: '1.4.4'
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
