<template>
	<div class="footers">
		<div class="plate1" v-if="pmkd">
			<div class="left">
				<div v-for="(item,index) in navtext2" class="list">
					<router-link :to="{name:item.address,query:{type:item.type,name:item.name}}">{{item.name}}</router-link>
					<ul v-if="item.childer&&item.childer.length>0">
						<li v-for="(e,m) in item.childer">
							<router-link :to="{name:e.address,query:{type:e.type,name:e.name}}">{{e.name}}</router-link>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="plate1" v-else>
			<div class="left">
				<div v-for="(item,index) in navtext" class="list">
					<router-link :to="{name:item.address,query:{type:item.type,name:item.name}}">{{item.name}}</router-link>
					<ul v-if="item.childer&&item.childer.length>0">
						<li v-for="(e,m) in item.childer">
							<router-link :to="{name:e.address,query:{type:e.type,name:e.name}}">{{e.name}}</router-link>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="plate2">
			<div>手机号：400-6565-300 座机：02968827879 邮箱：3332001250@qq.com 地址：西安市高新区绿地领海大厦B座808</div>
			<div>陕西墨智教育科技有限公司 备案号：<a href="https://beian.miit.gov.cn" target="_blank">陕ICP备17009726号-8</a></div>
		</div>
	</div>
</template>

<script>
	export default {
		components: {},
		data() {
			return {
				pmkd:false,
				navtext2: [{
					name: '首页',
					address: 'Index',
					type: '1-1',
				}, {
					name: '企业介绍',
					address: 'about',
					type: '2-1',
				}, {
					name: '校企合作',
					address: 'case_index',
					childer: [{
						name: '专业群建设辅助',
						address: 'case_index',
						type: '3-1'
					}, {
						name: '专业课程建设服务',
						address: 'case_index',
						type: '3-2'
					}, {
						name: '实训室教学模式创新',
						address: 'case_index',
						type: '3-3'
					}, {
						name: '课题研究与合作',
						address: 'case_index',
						type: '3-4'
					}, {
						name: 'OBE教学模式探索',
						address: 'case_index',
						type: '3-5'
					}, {
						name: '实践型师资培训',
						address: 'case_index',
						type: '3-6'
					}, {
						name: '一线资源服务',
						address: 'case_index',
						type: '3-7'
					}]
				}, {
					name: '行业服务',
					address: 'case_index',
					childer: [{
						name: '幼儿园加盟',
						address: 'case_index',
						type: '4-1'
					}, {
						name: '托育加盟',
						address: 'case_index',
						type: '4-2'
					}, {
						name: '托育课程研发',
						address: 'case_index',
						type: '4-3'
					}, 
					// {
					// 	name: '感觉统合教育指导',
					// 	address: 'case_index',
					// 	type: '4-4'
					// },
					]
				}, {
					name: '合作洽谈',
					address: 'cooperation'
				}],
				navtext: [ {
					name: '合作洽谈',
					address: 'cooperation'
				}, {
					name: '企业介绍',
					address: 'about',
					type: '2-1',
				}, {
					name: '校企合作',
					address: 'case_index',
					type: '3-1',
					childer: [{
						name: '专业群建设辅助',
						address: 'case_index',
						type: '3-1'
					}, {
						name: '专业课程建设服务',
						address: 'case_index',
						type: '3-2'
					}, {
						name: '实训室教学模式创新',
						address: 'case_index',
						type: '3-3'
					}, {
						name: '课题研究与合作',
						address: 'case_index',
						type: '3-4'
					}, {
						name: 'OBE教学模式探索',
						address: 'case_index',
						type: '3-5'
					}, {
						name: '实践型师资培训',
						address: 'case_index',
						type: '3-6'
					}, {
						name: '一线资源服务',
						address: 'case_index',
						type: '3-7'
					}]
				},{
					name: '行业服务',
					address: 'case_index',
					type: '4-1',
					childer: [{
						name: '幼儿园加盟',
						address: 'case_index',
						type: '4-1'
					}, {
						name: '托育加盟',
						address: 'case_index',
						type: '4-2'
					}, {
						name: '托育课程研发',
						address: 'case_index',
						type: '4-3'
					}
					// , {
					// 	name: '感觉统合教育指导',
					// 	address: 'case_index',
					// 	type: '4-4'
					// },
					]
				}]
			}
		},
		watch: {

		},
		created() {},
		mounted() {
			let l = document.body.clientWidth
			if(l>600){
				this.pmkd = true
			}else{
				this.pmkd = false
			}
		},
		methods: {

		}
	}
</script>

<style scoped lang="less">
	.footers {
		background-color: #333;

		.plate1 {
			padding: 40px 0;
			box-sizing: border-box;
			overflow: hidden;
			width: 1200px;
			margin: 0 auto;

			>* {
				display: inline-block;
				vertical-align: top;
				font-size: 0;
			}

			.left {
				width: 100%;

				>* {
					display: inline-block;
					vertical-align: top;
				}

				.list {
					margin-right: 16.5%;

					>a {
						display: inline-block;
						font-size: 18px;
						color: #fff;
						margin: 0 0 20px 0;
					}

					>ul {
						li {
							>a {
								display: inline-block;
								font-size: 14px;
								color: #fff;
								margin: 10px 0;
							}
						}
					}
				}

				>:last-child {
					margin-right: 0;
				}
			}

			.rigth {
				width: 20%;

				>div {
					display: inline-block;
					vertical-align: top;

					>img {
						display: block;
						margin: 0 auto;
					}

					>span {
						font-size: 12px;
						display: inline-block;
						margin-top: 20px;
						color: #fff;

					}
				}

				>:last-child {
					margin-left: 50%;
				}
			}
		}

		.plate2 {
			background-color: #000;
			padding: 20px 0;
			text-align: center;

			>div {
				font-size: 16px;
				color: #fff;

				a {
					font-size: 16px;
					color: #fff;
				}
			}

			>:first-child {
				margin-bottom: 15px;
			}
		}
	}

	@media screen and (max-width:900px) {
		.footers {
			.plate1 {
				padding: 20px 10px;
				width: 100%;

				.left {
					width: 100%;

					.list {
						width: 41.7%;
						>a{
							font-size: 16px;
							margin: 0 0 10px 0;
						}
						>ul{
							>li{
								>a{
									font-size: 12px;
									margin: 5px 0;
								}
							}
						}
					}

					>.list:nth-child(2n) {
						margin-right: 0;
					}
				}

				.rigth {
					display: none;
				}
			}
			.plate2 {
				padding: 20px 10px;
			
				>div {
					font-size: 14px;
			
					a {
						font-size: 14px;
					}
				}
			}
		}
	}
</style>
