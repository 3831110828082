<template>
	<div class="ban-save ban-saves">
		<headers></headers>
		<el-carousel class="bannerimgs" indicator-position="outside" height="600px">
			<el-carousel-item v-for="item in imglist" :key="item">
				<img style="width: 100%;" :src="item" alt="">
			</el-carousel-item>
		</el-carousel>
		<el-carousel class="phonebannerimgs" indicator-position="outside" height="200px">
			<el-carousel-item v-for="item in imglist2" :key="item">
				<img style="width: 100%;" :src="item" alt="">
			</el-carousel-item>
		</el-carousel>
		<!-- <div class="indeximg"><img src="../assets/img/asdas1.jpg" alt=""></div> -->
		<!-- <div class="phoneindeximg"><img src="../assets/img/asdas2.jpg" alt=""></div> -->
		<div class="cont">
			<div class="about">
				<div class="title">
					<span>关于我们</span>
				</div>
				<div class="detail">
					<div class="list1">
						<div class="aboutfbt">
							<img class="imgs" src="../assets/img/abouticon1.png">
							<div class="ftit">
								<p class="ty1">成立·立足</p>
								<p class="ty2">小信诚则大信立</p>
							</div>
						</div>
						<p class="ms">
							陕西墨智教育科技有限公司的前身为陕西金智教育咨询有限公司，成立于2006年，注册资金1680万，2022年4月更名为陕西墨智教育科技。本部位于陕西省西安市，是西北地区较早成长起来的面向高校、0-12岁儿童教育行业的综合性教育服务机构。
						</p>
					</div>
					<div class="list2">
						<div class="aboutfbt">
							<img class="imgs" src="../assets/img/abouticon2.png">
							<div class="ftit">
								<p class="ty1">成长·拓展</p>
								<p class="ty2">信为根，诚为干，务实为养，有成长</p>
							</div>
						</div>
						<p class="ms">
							自2006年成立以来，墨智教育科技始终专一于早幼托教育行业。现已发展成为拥有多家幼儿园、早教中心、托育中心、感觉统合训练中心等儿童教育实体的综合性、集团性教育研发和服务机构。面向高校和早幼托机构，提供早幼托教育行业师资培养、一线教案研发和输出、行业和岗位调研、品牌加盟和技术指导、高校专业建设咨询、高校技能课程输出和双师培养、现代学徒服务和技能大赛、人才培养模式和教学创新改革设计、信息技术+教育设计和实训室规划建设等服务。
						</p>
					</div>
					<div class="list3">
						<div class="aboutfbt">
							<img class="imgs" src="../assets/img/abouticon3.png">
							<div class="ftit">
								<p class="ty1">内涵·深耕</p>
								<p class="ty2">专于一业，静于一行；勤思，践行，而得精</p>
							</div>
						</div>
						<p class="ms">
							我们专一于早幼托行业16年，现已积累了多门一线课程教材、10多项版权成果、3大行业品牌，以及行业和高校的良好口碑。
						</p>
					</div>
					<div class="list4">
						<div class="aboutfbt">
							<img class="imgs" src="../assets/img/abouticon4.png">
							<div class="ftit">
								<p class="ty1">成就·发展</p>
								<p class="ty2">诚信务实，专业如一，勤思善行，得获而不骄，故恒远</p>
							</div>
						</div>
						<p class="ms">
							2019年起，公司紧跟行业发展、洞悉职业教育改革方向，将内涵建设、墨守诚信和创新发展确定为下一个五年期的发展核心，将行业核心应用转化为高校职业教育课程和实训评价，为职业教育改革注入新活力，力争在产品项目、客户服务、技术创新等方面再创新高！
						</p>
					</div>
				</div>
			</div>
			<div class="cooperation">
				<div class="title">
					<span>行业合作</span>
				</div>
				<div class="detail">
					<div ref="details">
						<div class="list">
							<div class="top">
								<img src="../assets/img/cooperationicon1.png">
							</div>
							<div class="bot" :style="{height:fsda}">
								<p>人才服务</p>
								<p>作为西安本土企业，陕西墨智教育开展“学前教育和早期教育”专业岗前培训业务，为当地幼儿园、早教、托育机构送人才。通过高品质的岗前培训，让学生有效学习，快速适应工作。提高就业率和就业品质。
								</p>
							</div>
						</div>
						<div class="list">
							<div class="top">
								<img src="../assets/img/cooperationicon2.png">
							</div>
							<div class="bot" :style="{height:fsda}">
								<p>师资培养</p>
								<p>陕西墨智教育作为西北地区国家人社部认证培训机构，致力于早幼教和育婴、营养人才的培养。开展《早期教育指导师》《儿童心理咨询师》等职业认证，同时针对一线需求开展主题培训和技能培训。已累计培养超过三万名优秀教育工作者。
								</p>
							</div>
						</div>
						<div class="list">
							<div class="top">
								<img src="../assets/img/cooperationicon3.png">
							</div>
							<div class="bot" :style="{height:fsda}">
								<p>课程教案</p>
								<p>陕西墨智教育13年成立教育教研部，深入研究婴幼儿早教课程。基于多年培训经验和一线调研经验，经过反复的理论研究和课程实践，推出金优宝贝系列早教课程。金优宝贝系列早教已在众多早教机构中实践应用。
								</p>
							</div>
						</div>
						<div class="list">
							<div class="top">
								<img src="../assets/img/cooperationicon4.png">
							</div>
							<div class="bot" :style="{height:fsda}">
								<p>培训认证</p>
								<p>开展《早期教育指导》《儿童心理咨询》《感觉统合训练指导》《奥尔夫音乐教育指导》《母婴护理》《托育教育指导》等职业培训认证项目。</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="development">
				<div class="title">
					<span>发展历程</span>
				</div>
				<div class="cont">
					<img class="img1" src="../assets/img/bag1.png" alt="">
					<img class="img2" src="../assets/img/bag2.png" alt="">
					<div class="shape1">
						<p class="tit1">2006年4月</p>
						<p class="tit2"><i class="icones"></i>2006年注册成立“陕西金唐职业”。</br>
							<i class="icones"></i>成为全国第三家、西北首家《育婴师》职业资格培训项目执行机构。</p>
					</div>
					<div class="shape2">
						<p class="tit1">2007-2009年</p>
						<p class="tit2"><i class="icones"></i>成功开办《育婴师师资》《育婴师》职业资格认证班超过20期。</br>
							<i class="icones"></i>成功引进人社部《儿童健康教育指导师》《早期教育指导师》等项目，并完成认证班超过15期。</p>
					</div>
					<div class="shape3">
						<p class="tit1">2010-2014年</p>
						<p class="tit2">
							<i class="icones"></i>创办金优学能儿童学习能力教育研究中心。</br>
							<i class="icones"></i>与安康学院、咸阳师范、陕学前、运城幼师、阳泉师专、晋中师专、宝鸡职业、延安职业、铜川职业、汉中职业等近20所院校开展学生技能培训合作。</br>
							<i class="icones"></i>收购新爱婴紫薇田园都市中心，并植入自有早教理念和课程。</br>
							<i class="icones"></i>参与多校人才培养方案研讨制定。</br>
							<i class="icones"></i>举办50多场教育类家长和教师讲座。</br>
							<i class="icones"></i>参与山西省国培项目授课。
						</p>
					</div>
					<div class="shape4">
						<p class="tit1">2015-2019年</p>
						<p class="tit2"><i class="icones"></i>创办“蒙洛西”托育品牌，并启动加盟。</br>
							<i class="icones"></i>创办“金智杯”早幼教人才技能大赛，并成功连续举行3届。</br>
							<i class="icones"></i>被评为陕西省著名商标、西安市著名商标、CCTV央视网推荐教育品牌单位。</br>
							<i class="icones"></i>举办早幼教行业讲座、活动100多场次。</br>
							<i class="icones"></i>举办多场学前专业简笔画、单项技能，以及最美护士等比赛。</br>
							<i class="icones"></i>旗下台湾伟童品牌幼儿园开园。</br>
							<i class="icones"></i>为多所本科、师专、高职院校提供一线技能课程入校园服务。</br>
							<i class="icones"></i>行业指导业务拓展至全国4省。</br>
							<i class="icones"></i>10多所高职、师专院校行业交流组织。</br>
							<i class="icones"></i>协办意大利国际奥尔夫音乐师资研修班<。/br>
							<i class="icones"></i>协办第51届中国园长发展论坛。</br>
							<i class="icones"></i>协办西咸新区母婴行业校企论坛。</br>
							<i class="icones"></i>冠名并承办咸阳师范职业技术学院学前教育专业首届技能大赛。</br>
							<i class="icones"></i>与20多所高职、师专院校达成校企合作 。</br>
							<i class="icones"></i>受邀在中国幼教年会做主题分享。</br>
							<i class="icones"></i>作为优秀校企合作单位参加“陕西城镇建设职业教育集团2019年工作会议”。</br>
							<i class="icones"></i>承建多所高职院校共计20多间实训室。</p>
					</div>
					<div class="shape5">
						<p class="tit1">2020年-2022年</p>
						<p class="tit2"><i class="icones"></i>成为多所本科、师专、高职院校深度校企合作单位和实践基地。</br>
							<i class="icones"></i>探索和制定有自主知识产权的“现代学徒制”“以赛代考”等创新模式。</br>
							<i class="icones"></i>疫情期间开办了家庭教育指导、教师技能培训的线上公益讲堂。</br>
							<i class="icones"></i>与西北工业学校联合开办幼儿保育专业。</br>
							<i class="icones"></i>与陕西艺术职业签订产学研训合作协议。</br>
							<i class="icones"></i>成功竞标西宁城市学院、安康职业、汉中职业、武威职业等院校的实训室建设项目。</br>
							<i class="icones"></i>完成幼儿园、早教、托育行业岗位调研。</br>
							<i class="icones"></i>业务拓展至全国7省。</p>
					</div>
					<div class="shape6">
						<p class="tit1">2022年</p>
						<p class="tit2">更名为陕西墨智教育科技有限公司</p>
					</div>
				</div>
			</div>
			<div class="partner">
				<div class="title">
					<span>合作伙伴</span>
				</div>
				<div class="imglist">
					<span><img src="../assets/img/partner3.png" alt=""></span>
					<span><img src="../assets/img/partner1.png" alt=""></span>
					<span><img src="../assets/img/partner2.png" alt=""></span>
					<span><img src="../assets/img/partner4.png" alt=""></span>
					<span><img src="../assets/img/partner5.png" alt=""></span>
					<span class="bords"><img src="../assets/img/partner12.png" alt=""></span>
					<span><img src="../assets/img/partner6.png" alt=""></span>
					<span><img src="../assets/img/partner7.png" alt=""></span>
					<span class="bords"><img src="../assets/img/partner8.png" alt=""></span>
					<span class="bords"><img src="../assets/img/partner9.png" alt=""></span>
					<span class="bords"><img src="../assets/img/partner10.png" alt=""></span>
					<span class="bords"><img src="../assets/img/partner11.png" alt=""></span>
				</div>
			</div>
			<div v-show="!zjshow" class="himg" @click="zjshow = true">
				<img src="../assets/img/index_2_34.png">
			</div>
		</div>
		<formdata></formdata>
		<footers></footers>
		<div :class="[zjshow ? 'dsdds dsddssa':'dsdds']" :style="{height:(segheight2)}">
			<el-button @click="zjshow = false" class="showbutton" type="primary" icon="el-icon-close" circle>
			</el-button>
			<div class="asww">
				<turn style="display: inline-block;" :width="(segwidth-200)" :height="(segheight-90)" :data='imglist3'>
				</turn>
			</div>
		</div>
	</div>
</template>

<script>
	import turn from "vue-flip-page"
	import headers from '../components/header.vue'
	import formdata from '../components/forsubmit.vue'
	import footers from '../components/footer.vue'
	export default {
		components: {
			headers,
			formdata,
			footers,
			turn
		},
		data() {
			return {
				segheight2: '',
				dialogtop: '2vh',
				segwidth: 1200,
				segheight: 1200,
				zjshow: false,
				fsda: '',
				imglist: [
					require('../assets/img/c8644a2.png')
				],
				imglist2: [
					require('../assets/img/90b38e98fa91855976d30ef2b935fdf.png')
				],
				imglist3: [{
						"picture_image": require('../assets/img/xcx (1).jpg')
					},
					{
						"picture_image": require('../assets/img/xcx (2).jpg')
					},
					{
						"picture_image": require('../assets/img/xcx (3).jpg')
					},
					{
						"picture_image": require('../assets/img/xcx (4).jpg')
					},
					{
						"picture_image": require('../assets/img/xcx (5).jpg')
					},
					{
						"picture_image": require('../assets/img/xcx (6).jpg')
					},
					{
						"picture_image": require('../assets/img/xcx (7).jpg')
					},
					{
						"picture_image": require('../assets/img/xcx (8).jpg')
					},
					{
						"picture_image": require('../assets/img/xcx (9).jpg')
					},
					{
						"picture_image": require('../assets/img/xcx (10).jpg')
					},
					{
						"picture_image": require('../assets/img/xcx (11).jpg')
					},
					{
						"picture_image": require('../assets/img/xcx (12).jpg')
					},
					{
						"picture_image": require('../assets/img/xcx (13).jpg')
					},
					{
						"picture_image": require('../assets/img/xcx (14).jpg')
					},
					{
						"picture_image": require('../assets/img/xcx (15).jpg')
					},
					{
						"picture_image": require('../assets/img/xcx (16).jpg')
					},
					{
						"picture_image": require('../assets/img/xcx (17).jpg')
					},
					{
						"picture_image": require('../assets/img/xcx (18).jpg')
					},
					{
						"picture_image": require('../assets/img/xcx (19).jpg')
					},
					{
						"picture_image": require('../assets/img/xcx (20).jpg')
					},
					{
						"picture_image": require('../assets/img/xcx (21).jpg')
					},
					{
						"picture_image": require('../assets/img/xcx (22).jpg')
					}
				]
			}
		},
		watch: {
			zjshow(val) {
				console.log(val)
				if (!val) {
					this.move()
					this.segheight2 = (this.segheight - 90) + 'px'
				} else {
					this.stop()
					this.segheight2 = '100vh'
				}
			}
		},
		created() {
			this.querySortImg()
		},
		mounted() {
			this.$nextTick(() => {
				let g = this.$refs['details'].children
				let v = this.$refs['dswww']
				let t = document.body.clientWidth
				let n = document.documentElement.clientHeight
				let u = document.documentElement.clientWidth
				let gh = 0
				console.log(u)
				g.forEach((e, m) => {
					if (gh < e.children[1].clientHeight) {
						gh = e.children[1].clientHeight
					}
				})
				this.fsda = gh + 'px'
				if (u > 900) {
					this.segwidth = t * 0.4
					this.segheight = n * 0.9
					this.dialogtop = '5vh'
				} else {
					this.segwidth = t * 0.9
					this.segheight = n * 0.7
					this.dialogtop = '10vh'
				}
				this.segheight2 = (this.segheight - 90) + 'px'
			})
		},
		methods: {
			stop() {
				document.body.style.overflow = 'hidden';
			},
			move() {
				document.body.style.overflow = ''; //出现滚动条
			},
			querySortImg() {
				let gh = []
				this.$post('querySortImg', {
					sortHead: 1,
					sortOrder: 0,
					sortFrom: 0
				}).then((res) => {
					if (res && res.length > 0) {
						this.imglist = []
						this.imglist2 = []
						res.forEach((e, m) => {
							let h = e.sortImg.split(',')
							h.forEach((v, b) => {
								if (e.sortSign == 0) {
									this.imglist.push(v)
								} else {
									gh.push(v)
								}
							})
						})
						if (!gh || gh.length <= 0) {
							this.imglist.forEach((f, g) => {
								f = f + '?x-bce-process=image/crop,x_500,y_200,w_701,h_400'
								this.imglist2.push(f)
							})
						} else {
							this.imglist2 = gh
						}
					} else {

					}
				})
				this.$nextTick(() => {

				})
			}
		}
	}
</script>

<style scoped lang="less">
	.phonebannerimgs {
		display: none;
	}

	.cont {

		.title {
			position: relative;
			display: inline-block;
			padding: 0 30px 0 50px;
			margin: 0 0 70px 0;

			>span {
				font-size: 25px;
				color: #f5974f;
			}

			>span::after {
				content: '';
				width: 4px;
				height: 20px;
				position: absolute;
				top: 23px;
				left: 5px;
				background-color: #f5974f;
				transform: rotate(30deg);
			}

			>span::before {
				content: '';
				width: 4px;
				height: 30px;
				position: absolute;
				top: 14px;
				left: 20px;
				background-color: #f5974f;
				transform: rotate(30deg);
			}
		}

		.title::after {
			content: '';
			width: 100%;
			height: 4px;
			position: absolute;
			top: 40px;
			left: 0;
			background-color: #f5974f;
		}

		.about {
			width: 1200px;
			margin: 20px auto 0 auto;
			text-align: center;
			font-size: 0;
			overflow: hidden;

			.detail {
				text-align: left;

				.aboutfbt {
					position: relative;

					>* {
						display: inline-block;
						vertical-align: middle;
					}

					.imgs {
						margin: 0 20px 0 0;
					}

					.ftit {
						.ty1 {
							font-size: 16px;
							color: #333;
							margin: 0 0 10px 0;
							letter-spacing: 3px
						}

						.ty2 {
							font-size: 14px;
							color: #666;
							letter-spacing: 4px
						}
					}

					.ftit::after {
						content: '';
						width: 17px;
						height: 17px;
						position: absolute;
						top: 3px;
						left: 16%;
						background-color: #e69c3b;
					}

					.ftit::before {
						content: '';
						width: 17px;
						height: 17px;
						position: absolute;
						top: 3px;
						left: 18%;
						background-color: #6fa0d4;
					}
				}

				.aboutfbt::after {
					content: '';
					width: 17px;
					height: 17px;
					position: absolute;
					top: 3px;
					left: 14%;
					background-color: #74c5cc;
				}

				.ms {
					font-size: 18px;
					color: #000;
					line-height: 30px;
					margin: 25px 0 0 0;
					letter-spacing: 2px
				}

				.list1,
				.list2,
				.list3 {
					margin: 0 0 60px 0;
				}
			}
		}

		.cooperation {
			width: 100%;
			font-size: 0;
			text-align: center;
			margin: 60px 0 0 0;

			.detail {
				width: 100%;
				text-align: left;
				background-color: #fba561;

				>div {
					width: 1200px;
					margin: 0 auto;
					font-size: 0;
					padding: 80px 0;
					box-sizing: border-box;

					>* {
						display: inline-block;
						vertical-align: top;
					}

					.list {
						margin-right: 60px;
						width: 21.2%;
						font-size: 0;

						>* {
							display: inline-block;
							vertical-align: top;
						}

						.top {
							background-color: #fff;
							border-radius: 10px 10px 0 0;
							height: 160px;
							overflow: hidden;
							font-size: 0;

							>img {
								width: 100%;
							}
						}

						.bot {
							padding: 15px;
							box-sizing: border-box;
							background-color: #fff;
							border-radius: 0 0 10px 10px;
							// min-height: 216px;

							>:first-child {
								color: #333;
								font-size: 18px;
								margin: 0 0 10px 0;
							}

							>:last-child {
								color: #999;
								font-size: 14px;
								text-indent: 27px;
								letter-spacing: 2px;
							}
						}
					}

					>:last-child {
						margin-right: 0;
					}
				}
			}
		}

		.development {
			width: 1200px;
			margin: 60px auto 0 auto;
			// height: 1900px;
			// font-size: 0;
			// // background: url(../assets/img/bag1.png) 100% no-repeat;
			// background-position: 0 160px;
			text-align: center;


			.cont {
				.img1 {
					width: 100%;
				}

				.img2 {
					display: none;
				}

				position: relative;
				text-align: left;

				.tit1 {
					font-size: 18px;
					color: #fff;
				}

				.tit2 {
					font-size: 16px;
					color: #fff;
					line-height: 25px;
					letter-spacing: 3px;
					margin: 10px 0 0 0;
					.icones {
						width: 5px;
						height: 5px;
						font-size: 0;
						background-color: #fff;
						border-radius: 100px;
						display: inline-block;
						vertical-align: middle;
						margin: 0 7px 0 0;
					}
				}

				.shape1 {
					border-radius: 10px;
					width: 440px;
					position: absolute;
					top: 80px;
					left: 35px;
					background-color: #f3958e;
					padding: 20px;
					box-sizing: border-box;
					box-shadow: -4px 8px 8px 6px #d8d8d8;
				}

				.shape1::after {
					content: '';
					height: 0;
					width: 0;
					right: -62px;
					top: 79px;
					position: absolute;
					overflow: hidden;
					font-size: 0;
					line-height: 0;
					border-color: transparent transparent #ff960000 #f3958e;
					border-style: solid solid dashed dashed;
					border-width: 4px 0px 23px 62px;
				}

				.shape2 {
					border-radius: 10px;
					width: 440px;
					position: absolute;
					top: 278px;
					right: 35px;
					background-color: #eccb5b;
					padding: 20px;
					box-sizing: border-box;
					box-shadow: -4px 8px 8px 6px #d8d8d8;
				}

				.shape2::after {
					content: '';
					height: 0;
					width: 0;
					left: -73px;
					top: 79px;
					position: absolute;
					overflow: hidden;
					font-size: 0;
					line-height: 0;
					border-color: #ff960000 #eccb5b transparent transparent;
					border-style: solid solid dashed dashed;
					border-width: 20px 74px 15px 0px;
				}

				.shape3 {
					border-radius: 10px;
					width: 440px;
					position: absolute;
					top: 629px;
					left: 35px;
					background-color: #b8d3a1;
					padding: 20px;
					box-sizing: border-box;
					box-shadow: -4px 8px 8px 6px #d8d8d8;
				}

				.shape3::after {
					content: '';
					height: 0;
					width: 0;
					right: -70px;
					top: 79px;
					position: absolute;
					overflow: hidden;
					font-size: 0;
					line-height: 0;
					border-color: transparent transparent #ff960000 #b8d3a1;
					border-style: solid solid dashed dashed;
					border-width: 8px 0px 20px 71px;
				}

				.shape4 {
					border-radius: 10px;
					width: 440px;
					position: absolute;
					top: 833px;
					right: 35px;
					background-color: #7ecaf1;
					padding: 20px;
					box-sizing: border-box;
					box-shadow: -4px 8px 8px 6px #d8d8d8;
				}

				.shape4::after {
					content: '';
					height: 0;
					width: 0;
					left: -60px;
					top: 245px;
					position: absolute;
					overflow: hidden;
					font-size: 0;
					line-height: 0;
					border-color: #ff960000 #7ecaf1 transparent transparent;
					border-style: solid solid dashed dashed;
					border-width: 10px 60px 13px 0px;
				}

				.shape5 {
					border-radius: 10px;
					width: 440px;
					position: absolute;
					top: 1318px;
					left: 35px;
					background-color: #d6b8d3;
					padding: 20px;
					box-sizing: border-box;
					box-shadow: -4px 8px 8px 6px #d8d8d8;
				}

				.shape5::after {
					content: '';
					height: 0;
					width: 0;
					right: -65px;
					top: 115px;
					position: absolute;
					overflow: hidden;
					font-size: 0;
					line-height: 0;
					border-color: transparent transparent #ff960000 #d6b8d3;
					border-style: solid solid dashed dashed;
					border-width: 0px 0px 26px 65px;
				}

				.shape6 {
					border-radius: 10px;
					width: 325px;
					position: absolute;
					top: 1505px;
					right: 145px;
					background-color: #d6ba9c;
					padding: 20px;
					box-sizing: border-box;
					box-shadow: -4px 8px 8px 6px #d8d8d8;
				}

				.shape6::after {
					content: '';
					height: 0;
					width: 0;
					left: -51px;
					top: 18px;
					position: absolute;
					overflow: hidden;
					font-size: 0;
					line-height: 0;
					border-color: #ff960000 #d6ba9c transparent transparent;
					border-style: solid solid dashed dashed;
					border-width: 5px 52px 13px 0px;
				}
			}
		}

		.partner {
			text-align: center;

			.imglist {
				text-align: left;
				width: 1200px;
				margin: 0 auto 0 auto;
				font-size: 0;

				>span {
					border: 1px solid transparent;
					display: inline-block;
					vertical-align: top;
					width: 15.2%;
					margin: 0 18px 30px 0;

					>img {
						width: 100%;
					}
				}

				>span:nth-child(6n) {
					margin-right: 0;
				}

				>span.bords {
					border-color: #eee;
				}
			}
		}
	}

	.himg {
		cursor: pointer;
		// background-color: #fff;
		font-size: 0;
		width: 100px;
		height: 90px;
		position: fixed;
		right: 0;
		top: 50%;
		z-index: 999999;

		>img {
			width: 100%;
		}
	}

	.dsdds {
		padding: 60px 0 0 0;
		box-sizing: border-box;
		z-index: 9999999;
		background-color: rgba(0, 0, 0, .5);
		position: fixed;
		width: 0;
		height: 0;
		opacity: 0;
		top: 0;
		right: 0;
		// transform: translate(-50%,-50%);
		text-align: center;
		transition: all .5s;

		.asww {
			display: inline-block;
			background: url(../assets/img/er3650.png);
			background-position: -40px -120px;
			background-size: 111% 125%;
			background-repeat: no-repeat;
			padding: 34px 79px 37px 64px;
			box-sizing: border-box;
		}

		.showbutton {
			display: none;
			right: 2%;
			top: 3%;
			position: absolute;
		}
	}

	.dsddssa {
		width: 100vw;
		height: 100vh;
		opacity: 1;

		.showbutton {
			display: inline-block;
		}
	}

	@media screen and (max-width:900px) {

		.bannerimgs {
			display: none;
		}

		.phonebannerimgs {
			display: block;
			margin-top: 64px;
		}

		.cont {

			.title {
				padding: 0 30px 0 50px;
				margin: 0 0 70px 0;

				>span {
					font-size: 18px;
				}

				>span::after {
					width: 4px;
					height: 20px;
					top: 13px;
					left: 5px;
				}

				>span::before {
					width: 4px;
					height: 30px;
					top: 5px;
					left: 20px;
				}
			}

			.title::after {
				width: 100%;
				height: 4px;
				top: 30px;
				left: 0;
			}

			.about {
				width: 97%;

				.detail {

					.aboutfbt {
						.imgs {
							width: 10%;
							margin: 0 10px;
						}

						.ftit {
							width: 80%;

							.ty1 {
								font-size: 14px;
								margin: 0 0 10px 0;
								letter-spacing: 2px
							}

							.ty2 {
								font-size: 12px;
								letter-spacing: 3px
							}
						}

						.ftit::after {
							width: 10px;
							height: 10px;
							top: 5px;
							left: 41%;
						}

						.ftit::before {
							width: 10px;
							height: 10px;
							top: 5px;
							left: 45%;
						}
					}

					.aboutfbt::after {
						width: 10px;
						height: 10px;
						top: 5px;
						left: 37%;
					}

					.ms {
						font-size: 14px;
						line-height: 25px;
						margin: 15px 0 0 0;
						letter-spacing: 1px
					}

					.list1,
					.list2,
					.list3 {
						margin: 0 0 60px 0;
					}
				}
			}

			.cooperation {
				margin: 60px 0 0 0;

				.detail {
					>div {
						width: 97%;
						padding: 60px 0;

						.list {
							margin-right: 4%;
							margin-bottom: 30px;
							width: 48%;

							.top {
								height: 120px;
							}

							.bot {
								padding: 10px;
								min-height: 220px;

								>:first-child {
									font-size: 16px;
									margin: 0 0 10px 0;
								}

								>:last-child {
									font-size: 12px;
									text-indent: 25px;
									letter-spacing: 1px;
								}
							}
						}

						.list:nth-child(2n) {
							margin-right: 0;
						}
					}
				}
			}

			.development {
				width: 97%;

				//     margin: 60px auto 0 auto;
				//     height: 970px;
				//     // background: url(../assets/img/bag2.png);
				//     background-size: 100% 100%;
				//     background-position: 0 85px;
				//     background-repeat: no-repeat;
				.cont {
					padding: 0 0 80px 0;

					// .img1{
					// 	display: none;
					// }
					// .img2{
					// 	display: inline-block;
					// 	width: 100%;
					// }
					.tit1 {
						font-size: 12px;
					}

					.tit2 {
						font-size: 10px;
						line-height: initial;
						letter-spacing: 1px;
						margin: 5px 0 0 0;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 8;
					}

					.shape1 {
						width: 150px;
						top: -20px;
						left: 0;
						padding: 10px;
						box-shadow: none;
					}

					.shape1::after {
						border-width: 0;
					}

					.shape2 {
						width: 130px;
						top: 38px;
						right: 15px;
						padding: 10px;
						box-shadow: none;
					}

					.shape2::after {
						border-width: 0;
					}

					.shape3 {
						width: 140px;
						top: 140px;
						left: 2px;
						padding: 10px;
						box-shadow: none;
					}

					.shape3::after {
						border-width: 0;
					}

					.shape4 {
						width: 140px;
						top: 233px;
						right: 5px;
						padding: 10px;
						box-shadow: none;
					}

					.shape4::after {
						border-width: 0;
					}

					.shape5 {
						width: 140px;
						top: 358px;
						left: 5px;
						padding: 10px;
						box-shadow: none;
					}

					.shape5::after {
						border-width: 0;
					}

					.shape6 {
						width: 130px;
						top: 418px;
						right: 20px;
						padding: 10px;
						box-shadow: none;
					}

					.shape6::after {
						left: -51px;
						top: 18px;
						border-width: 0px;
					}
				}
			}

			.partner {
				.imglist {
					width: 97%;
					font-size: 0;

					>span {
						width: 23%;
						margin: 0 1.8% 30px 0;

						>img {
							width: 100%;
						}
					}

					>span:nth-child(4n) {
						margin-right: 0;
					}

					>span:nth-child(6n) {
						margin-right: 1.8%;
					}

					>span:nth-child(12) {
						margin-right: 0%;
					}

					>span.bords {
						border-color: #eee;
					}
				}
			}
		}

		.himg {
			display: none;
		}
	}
</style>
<style lang="less">
	.ban-saves {
		.el-dialog {
			overflow: hidden;
			background-color: transparent;
			box-shadow: none;
		}

		.el-dialog__header {
			padding: 10px;
			display: none;
		}

		.el-dialog__body {
			padding: 0px;
		}
	}
</style>
