<template>
	<div class="body">
		<div :class="[navshow ? 'hoverheader header' : 'header']">
			<div class="logo"><img src="../assets/img/index_2_33.png" alt=""></div>
			<ul class="nav">
				<li v-for="(item,index) in navtext">
					<router-link :to="{name:item.address,query:{type:item.type,name:item.name}}">{{item.name}}
					</router-link>
					<div class="childer" v-if="item.childer&&item.childer.length>0">
						<span v-for="(e,m) in item.childer">
							<router-link :to="{name:e.address,query:{type:e.type,name:e.name}}">{{e.name}}</router-link>
						</span>
					</div>
				</li>
			</ul>
		</div>
		<div class="header_phone">
			<div class="logo"><img src="../assets/img/index_2_33.png" alt=""></div>
			<div @click="is_show ? is_show = false : is_show = true"
				:class="[!is_show ?'lent':'lent lents']">
				<span></span>
				<span></span>
				<span></span>
			</div>
			<el-collapse accordion v-model="collapsename" :class="[is_show ?'navss nav':'nav']">
				<template v-for="(item,index) in navtext">
					<el-collapse-item :name="'qw'+index" v-if="item.childer&&item.childer.length>0">
						<template slot="title">
							<router-link class="navto" :to="{name:item.address,query:{type:item.type,name:item.name}}">
								{{item.name}}</router-link>
						</template>
						<div class="childer" v-if="item.childer&&item.childer.length>0">
							<span v-for="(e,m) in item.childer">
								<router-link :to="{name:e.address,query:{type:e.type,name:e.name}}">{{e.name}}
								</router-link>
							</span>
						</div>
					</el-collapse-item>
					<div v-else class="lwnav">
						<router-link class="navto" :to="{name:item.address}">{{item.name}}</router-link>
					</div>
				</template>
			</el-collapse>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				collapsename: '',
				is_show: false,
				navshow: false,
				navtext: [{
					name: '首页',
					address: 'Index'
				}, {
					name: '企业介绍',
					address: 'about',
				}, {
					name: '校企合作',
					address: 'case_index',
					type: '3-1',
					childer: [{
						name: '专业（群）建设辅助',
						address: 'case_index',
						type: '3-1'
					}, {
						name: '专业课程建设服务',
						address: 'case_index',
						type: '3-2'
					}, {
						name: '实训室教学模式创新',
						address: 'case_index',
						type: '3-3'
					}, {
						name: '课题研究与合作',
						address: 'case_index',
						type: '3-4'
					}, {
						name: 'OBE教学模式探索',
						address: 'case_index',
						type: '3-5'
					}, {
						name: '实践型师资培训',
						address: 'case_index',
						type: '3-6'
					}, {
						name: '一线资源服务',
						address: 'case_index',
						type: '3-7'
					}]
				}, {
					name: '行业服务',
					address: 'case_index',
					type: '4-1',
					childer: [{
							name: '幼儿园加盟',
							address: 'case_index',
							type: '4-1'
						}, {
							name: '托育加盟',
							address: 'case_index',
							type: '4-2'
						}, {
							name: '托育课程研发',
							address: 'case_index',
							type: '4-3'
						},
						{
							name: '感统加盟',
							address: 'case_index',
							type: '4-4'
						},
					]
				}, {
					name: '合作洽谈',
					address: 'cooperation'
				}]
			}
		},
		watch: {
			'$route.query.type'(val) {
				// console.log(val)
				this.is_show = false
			},
			// is_show(val){
			// 	// console.log(val)
			// 	if(!val){
			// 		this.move()
			// 	}else{
			// 		this.stop()
			// 	}
			// }
		},
		created() {},
		mounted() {
			let that = this
			window.addEventListener('scroll', that.handleScrollx, true)
		},
		methods: {
			handleScrollx() {
				var top = Math.floor(document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset)
				// console.log(top)
				if (top > 50) {
					this.navshow = true
				} else {
					this.navshow = false
				}
			},
			stop() {
				document.body.style.overflow = 'hidden';
			},
			move() {
				document.body.style.overflow = ''; //出现滚动条
			}
		}
	}
</script>

<style scoped lang="less">
	.body {
		border-bottom: 1px solid #FAECD8;
		position: relative;

		.header {
			padding: 10px 0;
			width: 93%;
			margin: 0 auto;

			>* {
				display: inline-block;
				vertical-align: middle;
			}

			.logo {
				width: 17%;
				font-size: 0;

				>img {
					width: 100%;
				}
			}

			.nav {
				text-align: center;
				// float: right;
				margin: 0 0 0 49%;

				>li {
					display: inline-block;
					vertical-align: top;
					margin: 0 20px;
					transition: all 1s;
					border-bottom: 2px solid transparent;
					position: relative;
					padding: 0 0 10px 0;

					>a {
						font-size: 16px;
					}

					.childer {
						z-index: 999;
						max-height: 0;
						overflow: hidden;
						transition: all 1s;
						box-sizing: border-box;
						width: 150px;
						position: absolute;
						top: 40px;
						left: -34px;
						font-size: 0;

						>span {
							border-top: 1px solid #e89806b8;
							background-color: #fdb300;
							display: block;
							font-size: 16px;
							padding: 15px 0;

							>a {
								font-size: 14px;
								color: #fff;
								display: inline-block;
								text-overflow: ellipsis;
								white-space: nowrap;
								width: 100%;
								overflow: hidden;
							}
						}

						>span:hover {
							background-color: #fdd100;
						}
					}


				}

				>li:hover {

					// border-color: #fdd100;
					>a {
						color: #e13ea9;
					}

					.childer {
						// visibility: visible;
						// opacity: 1;
						max-height: 100vh;
						// left: 50px;
					}
				}

				>li.coloer {
					border-color: #008000;
				}

				>li:last-child {
					margin-right: 0;
				}
			}
		}

		.hoverheader {
			position: fixed;
			top: 0;
			left: 0;
			z-index: 999;
			background-color: #fff;
			padding: 10px 60px;
			width: 100%;
			box-sizing: border-box;

			.nav {
				// margin: 32px 126px 0 0;

				>li:hover {
					border-color: #fdd100;

					>a {
						color: #333;
					}
				}
			}
		}

		.header_phone {
			display: none;
		}
	}

	@media screen and (max-width:900px) {
		.body {
			position: fixed;
			width: 100%;
			left: 0;
			top: 0;
			z-index: 999;
			border: none;

			.header,
			.hoverheader {
				display: none;
			}

			.header_phone {
				background-color: #fff;
				display: block;
				position: relative;
				padding: 15px 0;
				background-color: #fff;
				>* {
					display: inline-block;
					vertical-align: middle;
				}

				.logo {
					font-size: 0;

					>img {
						width: 200px;
					}
				}

				.lent {
					margin: 0 2% 0 0;
					width: 25px;
					float: right;

					>span {
						display: block;
						width: 100%;
						height: 2px;
						margin-bottom: 8px;
						background-color: #fdd100;
						transition: all 1s;
						opacity: 1;
					}

					>:last-child {
						margin: 0;
					}
				}

				.lents {
					>:nth-child(1) {
						transform: translateY(10px) rotate(45deg);
						transform-origin: center center
					}

					>:nth-child(3) {
						display: none;
						opacity: 0;
						width: 0;
						background-color: #fff;
					}

					>:nth-child(2) {
						transform: translateY(0px) rotate(-45deg);
						transform-origin: center center
					}
				}

				.nav {
					// display: none;
					border: none;
					position: absolute;
					left: 0;
					top: 57px;
					z-index: 99;
					transition: all 1s;
					height: 0;
					// opacity: 0;
					overflow: hidden;
					background-color: #fff;
					width: 100%;
					padding: 0 10px;
					box-sizing: border-box;

					.navto {
						color: #333;
						font-size: 16px;
					}

					.childer {
						>span {
							display: block;
							padding: 10px 0 10px 10px;

							border-bottom: 1px solid #EBEEF5;

							>a {
								font-size: 14px;
								color: #999;
								width: 100%;
								display: inline-block;
							}
						}
					}

					.lwnav {
						// padding: 15px 0;
						border-bottom: 1px solid #EBEEF5;
						height: 48px;

						>a {
							width: 100%;
							display: inline-block;
							font-size: 16px;
							line-height: 48px;
						}
					}

					>:last-child {
						border-bottom: none;
					}
				}

				.navss {
					transition: all 1s;
					// opacity: 1;
					display: inline-block;
					height: 100vh;
					// height: 100px;
				}
			}
		}
	}
</style>
