<template>
	<div class="body">
		<div class="title">联系我们</div>
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="khform" label-width="80px">
			<el-form-item label="姓名" prop="userName">
				<el-input v-model="ruleForm.userName"></el-input>
			</el-form-item>
			<el-form-item label="联系电话" prop="userTel">
				<el-input v-model="ruleForm.userTel"></el-input>
			</el-form-item>
			<el-form-item label="备注" prop="userDes">
				<el-input type="textarea" v-model="ruleForm.userDes"></el-input>
			</el-form-item>
			<div>
				<el-button type="primary" @click="submitForm()">提交</el-button>
			</div>
		</el-form>
	</div>
</template>

<script>
	export default {
		data() {
			var Phone = (rules, value, callback) => {
				if (value === '') {
					callback(new Error('请输入密码'));
				} else {
					var isPhone = /^([0-9]{3,4}-)?[0-9]{7,8}$/;
					//var isMob=/^((\+?86)|(\(\+86\)))?(13[012356789][0-9]{8}|15[012356789][0-9]{8}|18[02356789][0-9]{8}|147[0-9]{8}|1349[0-9]{7})$/;
					//var isMob = /^[1][3,4,5,7,8,9][0-9]{9}$/;
					var isMob = /^1[0-9]{10}$/;
					if (isPhone.test(value) || isMob.test(value)) {
						callback();
					} else {
						callback(new Error('电话输入不合法。如果是座机号请在区号后加-'));
					}
				}
			};
			return {
				ruleForm: {
					userName: '',
					userTel: '',
					userDes: ''
				},
				rules: {
					userName: [{
						required: true,
						message: '请输入姓名',
						trigger: 'blur'
					}],
					userTel: [{
						required: true,
						validator: Phone,
						trigger: 'blur'
					}]
				}
			}
		},
		created() {},
		mounted() {},
		methods: {
			submitForm() {
				this.$refs['ruleForm'].validate((valid) => {
					if (valid) {
						this.ruleForm.sign = 0
						this.$post('addUser',this.ruleForm).then((res)=>{
							console.log(res)
							this.$notify({
								title: '提示',
								message: '信息已提交，稍后会有专人联系您',
								type: 'success'
							});
							this.ruleForm = {
								userName: '',
								userTel: '',
								userDes: ''
							}
						})
					} else {
						this.$notify({
							title: '提示',
							message: '信息不完整',
							type: 'warning'
						});
					}
				});
			}
		}
	}
</script>

<style scoped lang="less">
.body{
	width: 1200px;
	margin: 20px auto 40px auto;
	font-size: 0;
	border: 1px solid #eee;
	.title{
		font-size: 20px;
		color: #f29b58;
		padding: 20px 0 20px 10px;
		box-sizing: border-box;
	}
	>form.khform{
		width: 100%;
		padding: 15px;
		box-sizing: border-box;
	}
}
</style>
<style lang="less">
	.body{
		>form.khform{
			.el-form-item{
				display: inline-block;
				width: 50%;
				vertical-align: top;
				margin-bottom: 60px;
			}
			.el-input{
				// width: 30%;
			}
			>:last-child{
				display: block;
				width: 100%;
				text-align: center;
				margin-bottom: 80px;
			}
		}
	}
</style>
<style lang="less" scoped="scoped">
	@media screen and (max-width:900px){
		.body{
			margin: 0 auto 20px auto;
			box-sizing: border-box;
			width: 96%;
			.title{
				font-size: 16px;
			}
			>form.khform{
				padding: 0 10px;
				box-sizing: border-box;
				.el-form-item{
					width: 100%;
					margin-bottom: 40px;
				}
				.el-input{
					// width: 30%;
				}
				>:last-child{
					margin-bottom: 40px;
				}
				.el-form-item__label{
					font-size: 12px;
				}
			}
		}
	}
</style>
<style lang="less">
	@media screen and (max-width:900px){
		.khform .el-form-item__label{
			font-size: 12px;
		}
	}
</style>
